import React from 'react';
import Layout from '../components/Layout';
import pic1 from '../assets/images/pic01.jpg';
const BlogPost = () => (
  <Layout fullMenu>
    <section>
      <div>
        <header></header>
        <div className="blog-wrap">
          <div className="blog-space" />
          <h1 className="major">Moving during the corona virus </h1>
          <h3>
          {'The corona virus has changed the way that humans being around the glove are living our day to day lives. Even here in the United States we are now faced with a new set of challenges on a daily basis. Obviously if you don’t need to move right now it may be best to put your move on hold until after the corona pandemic passes. But what if you need to move right now?'}</h3>

          <h3>Are movers still working during Corona?</h3>
          <p>{'Right now movers are still working and servicing most areas without any delays. It could be though that in the next few weeks smaller moving companies may decide to shut down, and that within a month even the biggest moving companies will start limiting where they are servicing. If you have already scheduled a move, you may want to call and confirm that your movers are still able to come.'}
            <span className="image left">
              <img src={''} alt="" />
            </span>
            {'If your move is planned for some time in the next few months then the truth of the matter is that it’s at this point impossible for anyone to say exactly what will happen. The best step you can take now is to continue planning your move, getting moving quotes, researching moving companies and doing what you can to get ready for your move.'}<blockquote>
            {'The current operations by our moving services company, Allied, is devoted to work that is deemed essential to helping people to shelter in place. We are monitoring the evolving state and local orders, as well as working with our Agents to implement strict protocols around the health and safety of our crews, customers and larger communities. At the end of the day, these cases involve a need to get a family their belongings who must move or come home so that they can safely shelter in place at their home in compliance with the current COVID-19 directives.'}
            </blockquote>
            {'Most of the nations top moving companies will do their best to work hard and get you and your family where you need to be right now. What will surely change effective immediately is that fewer movers will be offering in home estimates, so you will need to get your moving quotes virutally over the phone. You can also take pictures of the items you need to ship to help the movers get an accurate idea of exactly what is moving so that the moving quote you receive is also accurate.Some companies also use 3rd party apps which leverage machine learning algorhithms to give exact guaranteed pricing.'}
          </p>
          <h3>Essential tips for moving during the Coronavirus Pandemic
</h3>
          <p>
            {' '}
            <span className="image right">
              <img src={''} alt="" />
            </span>
            There are things we can all do together to move your family safely during this difficult time. Check out this great article put out by the <a href="https://www.moving.org/guidance-for-consumers-moving-during-the-coronavirus-outbreak/">American Moving and Storage Association</a>.</p>

          <h3>Only use new packing materials</h3>
          <p>This virus can last a very long time on surfaces, possibly <a href="https://abcnews.go.com/US/wireStory/tests-show-virus-lives-surfaces-days-69534882">as long as 3 days</a> so it is best to avoid free moving boxes from stores, or give away moving boxes from people who just moved. You may find that the easiest route here is to just hire the movers for a full packing service. A few dollars is well worth avoiding this potentially deadly virus. </p>
          <h3>{'If you don’t need to move now, don’t move!'}</h3>
          <p>  {'If you don’t need to move in the next few months, then you may as well wait it out until later. Don’t worry, when corona virus passes long distance movers will still be around to help with your move. If you one of the groups of people who is at an especially high risk you should really strongly consider whether you need to move now, or if you can afford to wait for the virus to pass.'}</p>
          <h3>If you or your family are sick and may have the corona virus please tell the movers immediately!</h3>
          <p>{'Like always honesty is the best policy. Please if you think you may have been exposed to the virus let your movers know so that we can plan how to best help you while avoiding risk. There are things we can do to make sure we can still move you even if you are in quarantine, and even if you have tested positive for the virus. But if we don’t know, we don’t know. So please help us stay safe in this tough time. '}</p>
          <h3>Help the movers practice best hygiene protocols</h3>
<p>{'To help the movers in following the best safety protocols, make sure to have the tools they need to move you safely. Since the movers will be in and out of the house and touching lots of things you want want to have hand soap, and paper towels so that the movers can wash their hands. Spray bottles and rags with a 10% bleach solution, or rubbing alcohol are also great tools the movers can use to make sure everything stays as clean as possible. The movers will most likely not be wearing masks because masks are in short supply and need to go to our medical professionals and law enforcement who need them most but if need be we can bring our own supplies. Let us know if we need to bring soap, latex gloves, or anything else with us.'}</p>
<h3>Have you planned how you will get to your new home?</h3>
<p>{'Regular services like airlines and rental car agencies may not be operating. Make sure to book your hotel rooms in advance and bring food and supplies with you to last the trip. You can’t rely on restaurants being open in the next few weeks. '}</p>
          <ul className="actions">
            <li>
              <a href="/Blog" className="blog-button">
                More Articles
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
);
export default BlogPost;
